<template>
    <div class="w-100" :style="cssProps">
        <div class="col-12 extra-buttons">
            <a href="#" @click.prevent="openPassagengerList(item)" class="btn btn-outline-primary order-item-action send-list">Enviar lista de passageiros</a>
        </div>
        
        <div id="passenger-list" :style="cssProps" >
          <b-modal 
          no-close-on-backdrop size="xl" id="passenger-list" ref="passenger-list" @ok="handleOk" :style="cssProps">
            <div id="notification">
              <div class="notification-body">
                <h4>Passageiro {{ this.editingPassenger ? 'atualizado' : 'adicionado' }} com sucesso</h4>
                <p>Você já adicionou {{ this.passengers.length }} passageiros a sua lista</p>
              </div>
            </div>
            <div class="title">
              <h4>{{ this.list_passanger_title }}</h4>
            </div>
            <div id="passengersMobileModal"  :style="cssProps">
              <h4>Insira os dados dos passageiros</h4>
              <small class="passenger-count"><span>{{ this.passengers.length }}</span>
                {{ this.passengers.length > 1 ? 'passageiros adicionados' : 'passageiro adicionado' }}
              </small>

              <div class="form">

                <div class="row">
                  <div class="col-12">
                    <label for="">Nome completo</label>
                    <input type="text" id="username-field" v-model="passenger.name" placeholder="Nome completo" required>
                  </div>

                  <div class="col-12">
                    <label for="">Documento</label>
                    <input type="text" v-model="passenger.document" placeholder="Documento" required>
                  </div>

                  <div class="col-12">
                    <label for="">Tipo de documento</label>
                    <select v-model="passenger.documentType" required>
                      <option value="" disabled selected>Selecione</option>
                      <option v-for="documentType in documentTypes" :key="documentType.key" :value="documentType.key">{{ documentType.label }}</option>
                    </select>
                  </div>  

                  <div class="col-12">
                    <label for="">Emissor</label>
                    <input type="text" v-model="passenger.documentIssuer" placeholder="Emissor" required>
                    </div>  

                  <div class="col-12">
                    <label for="">É criança?</label>
                    <select v-model="passenger.isChild" required>
                      <option value="" disabled selected>Selecione</option>
                      <option v-for="personOption in personOptions" :key="personOption.key" :value="personOption.key">{{ personOption.label }}</option>
                    </select>
                  </div>
                
                  <div class="col-12">
                    <label for="">Data de nascimento</label>
                    <input type="text" v-model="passenger.birthDate" placeholder="Data de nascimento" required>
                  </div>

                  <div class="col-12 actions">

                    <button class="btn btn-secondary" @click="closePassengerModal">Voltar</button>

                    <button class="btn btn-outline-primary" @click.prevent="addPassenger">{{ this.editingPassenger ? 'Atualizar' : 'Adicionar' }}</button>
                  </div>
                </div>
              </div>
            </div>
                
                <div :style="cssProps" class="passenger-sheet">
                  <div class="row">  
                    <div class="col-12">
                      <div class="passenger-import-card d-flex flex-column flex-xl-row justify-content-between mt-4 mb-3">
                        <p class="mb-0 col-md-7 col-12"><strong class="color-primary">DICA: </strong>Você pode baixar a planilha de modelo para enviar todos os passageiros de uma só vez. É só baixar, preencher e enviar aqui mesmo nesta tela.</p>
                        <div class="actions col-xl-4 col-md-7 col-12 d-flex flex-column flex-xl-row justify-content-end mt-xl-0 mt-4">
                          <a href="/modelo-lista-de-passageiros.xlsx" :download="filename" class="btn-action download w-100 col-xl-6 col-12 mr-2 mb-2 mb-xl-0">Baixar modelo <font-awesome-icon class="icon alt" :icon="['fas', 'cloud-download-alt']" /></a>
                          <div class="btn-action import col-xl-6 w-100 col-12" @click.prevent="openImport">Enviar arquivo <font-awesome-icon class="icon alt" :icon="['fas', 'upload']" /></div>
                          <input type="file" class="d-none" id="import-file" @change="onFileChange"/>
                        </div>
                      </div>
                    </div>
                  </div>  
                  <div class="row mb-3">
                    <hr class="w-100 mt-3"/>
                    <div class="col-xl-9 col-7">
                      <h5 class="mt-2 mt-2 d-flex d-md-block flex-column">Lista de passageiros - <small>({{ this.seats }} Lugares)</small></h5>
                      </div>
                      <div class="col-xl-3 col-5 text-right d-flex justify-content-end align-items-center">
                        <small class="color-primary">
                          <a v-if="this.passengers.length > 0" @click.prevent="cleanList">Limpar lista</a>
                        </small>
                      </div>
                    </div>
                  <div>
                      <small v-html="this.$store.state.companyDetails.passengers_disclaimer"></small>
                  </div>

                  <div class="d-flex d-xl-none">
                    <div class="table-responsive">
                      <table class="table mobile-table">
                        <thead>
                          <tr>
                            <th scope="col">Nome</th>
                            <th scope="col">Documento</th>
                            <th scope="col">Tipo</th>
                            <th scope="col">Emissor</th>
                            <th scope="col">É criança?</th>
                            <th scope="col">Data de nasc</th>
                            <th scope="col"></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(passenger, index) in passengers" :key="index">
                            <td>{{ passenger.name }}</td>
                            <td>{{ passenger.document }}</td>
                            <td>{{ getDocumentLabel(passenger.documentType) }}</td>
                            <td>{{ passenger.documentIssuer }}</td>
                            <td>{{ passenger.isChild === 'yes' ? 'Sim' : 'Não' }}</td>
                            <td>{{ passenger.birthDate }}</td>
                            <td class="actions">
                              <button class="btn btn-edit btn-sm" @click="editSpecificPassenger(passenger.id)" :passengerid="passenger.id">Editar</button>
                              <button class="btn btn-delete btn-sm" @click="removeSpecificPassenger(passenger.id)" :passengerid="passenger.id">Excluir</button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="d-none d-xl-flex">
                    <fretatech-excel-editor 
                      ref="excelEditor" 
                      no-footer no-paging :localized-label="excelEditorLabels" v-model="this.passengers" @cell-focus="onCellFocus">
                        <fretatech-excel-column field="name"           label="Nome"       type="string" width="30%" />
                        <fretatech-excel-column field="document"       label="Documento"  type="string" width="14%" />
                        <fretatech-excel-column field="documentType"   label="Tipo"       type="map" width="13%" :options="{RG: 'RG', CNH: 'CNH', PASSAPORTE: 'Passaporte', CERTIDAO: 'Certidão'}" />
                        <fretatech-excel-column field="documentIssuer" label="Emissor"    type="string" width="13%" />
                        <fretatech-excel-column field="isChild"        label="É criança?" type="map" width="15%" :options="{yes: 'Sim', no: 'Não'}"/>
                        <fretatech-excel-column field="birthDate"      label="DATA NASC." type="date"   width="15%" />                  
                    </fretatech-excel-editor>
                  </div>
                  
                  <div v-if="this.passengers.length === 0">
                      <p class="text-secondary mt-2 text-center">Você não cadastrou nenhum passageiro ainda</p>
                  </div>
                
                </div>

              <template #modal-footer="{ ok, cancel}">
                  <div class="row" :style="cssProps">
                      <b-button size="sm" variant="danger" @click="cancel()">
                      Cancelar
                      </b-button>

                      <b-button class="btn-outline-primary d-xl-block d-none" size="sm"  @click="ok()">
                          Salvar lista de passageiros
                      </b-button>                     
                 
                      <button class="add-passenger btn-outline-primary d-flex d-xl-none" @click="showPassengersModal">
                        <font-awesome-icon class="icon alt mr-1" :icon="['fas', 'plus']" />
                        Adicionar passageiros
                      </button>
                  </div>
              </template>
          </b-modal>
        </div>
    </div>
  </template>

<script>
import DatePicker from '@sum.cumo/vue-datepicker'
import axios from 'axios'
import FileSaver from 'file-saver'
import readXlsxFile from 'read-excel-file'


export default {
  name: 'OrderExtraDetail',
  props: ['item', 'order'],
  components: { DatePicker },
  data () {
    return {
      filename: 'modelo-lista-de-passageiros-' + this.slugDate(new Date().toLocaleString()) +'.xlsx',
      list_passanger_title: '',
      seats: 0,
      passenger: {
        id: 0,
        name: '',
        document: '',
        documentType: '',
        documentIssuer: '',
        isChild: '',
        birthDate: ''
      },
      editingPassenger: false,
      editingBirthField: false,
      rowPos: 0,
      docEnum: {
          'CERTIDÃO': 'CERTIDAO',
          'PASSAPORTE': 'PASSAPORTE',
          'CNH': 'CNH',
          'RG': 'RG',
      },
      excelEditorLabels: {
        footerLeft: (top, bottom, total) => `Registro ${top} a ${bottom} de ${total}`,
        first: 'Primeiro',
        previous: 'Anterior',
        next: 'Próximo',
        last: 'Último',
        footerRight: {
            selected: 'Selecionado:',
            filtered: 'Filtrado:',
            loaded: 'Carregado:'
        },
        processing: 'Processando',
        tableSetting: 'Configuração da Tabela',
        exportExcel: 'Exportar Excel',
        importExcel: 'Importar Excel',
        back: 'Voltar',
        reset: 'Padrão',
        sortingAndFiltering: 'Ordenação e Filtragem',
        sortAscending: 'Ordenar Crescente',
        sortDescending: 'Ordenar Decrescente',
        near: '≒ Próximo',
        exactMatch: '= Correspondência Exata',
        notMatch: '≠ Não Corresponde',
        greaterThan: '&gt; Maior Que',
        greaterThanOrEqualTo: '≥ Maior ou Igual A',
        lessThan: '&lt; Menor Que',
        lessThanOrEqualTo: '≤ Menor ou Igual A',
        regularExpression: '~ Expressão Regular',
        customFilter: 'Filtro Personalizado',
        listFirstNValuesOnly: n => `Listar apenas os primeiros ${n} valores`,
        apply: 'Aplicar',
        noRecordIsRead: 'Nenhum registro foi lido',
        readonlyColumnDetected: 'Coluna Somente Leitura detectada',
        columnHasValidationError: (name, err) => `A coluna ${name} possui erro de validação: ${err}`,
        noMatchedColumnName: 'Nenhum nome de coluna correspondente',
        invalidInputValue: 'Valor de entrada inválido',
        missingKeyColumn: 'Coluna chave ausente',
        noRecordIndicator: 'Nenhum registro foi adicionado à lista'

      },
      nameState: null,
      passengers: [],
      personOptions: [
        {
          key: 'yes',
          label: 'Sim'
        },
        {
          key: 'no',
          label: 'Não'
        }
      ],
      documentTypes: [
        { key: 'rg', label: 'RG' }, { key: 'cnh', label: 'CNH' }, { key: 'passaporte', label: 'Passaporte' }, { key: 'CERTIDÃO', label: 'Certidão' }
      ]
    }
  },
  methods: {

    closePassengerModal () {
      document.getElementById('passengersMobileModal').classList.toggle('active')
      document.querySelector('.passenger-sheet').classList.toggle('d-none')
      document.querySelector('#passenger-list .title').classList.toggle('d-none')
      document.querySelector('#passenger-list .modal-footer').classList.toggle('d-none')
    },

    showPassengersModal () {
      document.getElementById('passengersMobileModal').classList.toggle('active')
      document.querySelector('.passenger-sheet').classList.toggle('d-none')
      document.querySelector('#passenger-list .title').classList.toggle('d-none')
      document.querySelector('#passenger-list .modal-footer').classList.toggle('d-none')
      
    },
    addPassenger() {

      if (this.passengers) {

        
        if(this.passenger.name === '' || this.passenger.document === '' || this.passenger.documentType === '' || this.passenger.documentIssuer === '') {
          this.$swal('Ops!', 'Você precisa preencher os campos obrigatórios para adicionar um passageiro.', 'error')
          return
        }else{
          
          if(this.editingPassenger) {

            let passenger = this.passengers.find(p => p.id === this.passenger.id);
            let index = this.passengers.indexOf(passenger);
            this.passengers.splice(index, 1, this.passenger)
        
          }else{ 
            this.passengers.push({
              id: this.passengers.length + 1,
              name: this.passenger.name,
              document: this.passenger.document,
              documentType: this.passenger.documentType,
              documentIssuer: this.passenger.documentIssuer,
              isChild: this.passenger.isChild,
              birthDate: this.passenger.birthDate
            })
          }
          
          var notification = document.getElementById('notification');
          notification.classList.add('active')
          
          this.passenger = {
            id: 0,
            name: '',
            document: '',
            documentType: '',
            documentIssuer: '',
            isChild: '',
            birthDate: ''
          }

          setTimeout(async () => {
            notification.classList.remove('active')
            this.editingPassenger = false;

            const orderItemId = this.item.id
            await axios.post(process.env.API_URL + `services/${orderItemId}/passengers/insert`, { 
              passengers: this.passengers,
              orderItemId: orderItemId
            }).then((response) => {
              document.getElementById('username-field').focus();
            })
            
          }, 2500);
        }
      
      }
    },
    slugDate(date) {
      var formattedValue = 
        date.replace(/\//g, '-')
        .replace(/:/g, '-')
        .replace(/ /g, '-')
        .replace(/,/g, '')

        return formattedValue;
    },
    async onCellFocus({ rowPos, colPos, cell, record }) {
      const cellRcAttribute = cell.getAttribute('cell-rc');
      if (cellRcAttribute && cellRcAttribute.includes('birthDate')) {
        this.editingBirthField = true;
      }else{
        this.editingBirthField = false;
      }

      this.rowPos = rowPos;
    },
    async addEventListenersToBirthDateCells() {
      const inputBox = document.querySelector('#inputBox');
      inputBox.addEventListener('keyup', this.onBirthDateInput);

    },
    formatDate(inputValue) {
      const cleanedInput = inputValue.replace(/[^\d]/g, '');
      const day = cleanedInput.slice(0, 2);
      const month = cleanedInput.slice(2, 4);
      const year = cleanedInput.slice(4, 8);

      let formattedDate = '';

      if (day.length > 0) {
        formattedDate += day;
      }

      if (month.length > 0) {
        formattedDate += `/${month}`;
      }

      if (year.length > 0) {
        formattedDate += `/${year}`;
      }

      if(year.length === 4) {
        const currentYear = new Date().getFullYear();
        if(year > currentYear) {
          this.$swal('Ops!', 'Data de nascimento inválida', 'error')
          return '';
        }
      }

      if(formattedDate.length === 10) {
        const isValidDate = this.moment(formattedDate, 'DD/MM/YYYY', true).isValid();

        if(!isValidDate) {
          this.$swal('Ops!', 'Data de nascimento inválida', 'error')
          return '';
        }

      }
  
      return formattedDate;
    },
    onBirthDateInput(event) {
      if (this.editingBirthField) {
        setTimeout(() => {
          var cell = event.target;
          var inputValue = cell.value;
          var formattedValue = this.formatDate(inputValue);
          event.target.value = formattedValue;
        }, 250);
        // this.updateRowValue(this.rowPos, 'birthDate', formattedValue);
      }
    },
    async cleanList () {
      return new Promise((resolve, reject) => {
        this.$swal({
          title: 'Tem certeza?!',
          text: 'Você está prestes a excluir toda a lista de passageiros',
          showDenyButton: true,
          confirmButtonText: 'Confirmar',
          denyButtonText: `Cancelar`
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.deleteConfirmation = 1
            this.passengers = []
            await this.savePassengerList().then((response) => {
              if(window.innerWidth > 1024) {
                this.passengers = Array.from({length: this.seats}, (v, i) => ({
                  id: i,
                  name: '',
                  document: '',
                  documentType: '',
                  documentIssuer: '',
                  isChild: '',
                  birthDate: ''
                }));
              }
              this.$swal('Tudo certo', 'A lista foi excluída!', 'success')
            })
          }
        })
      })
    },

    updateRowValue (row, field, value) {
      this.passengers[row][field] = value
      // force re-render
      this.passengers = [...this.passengers]
     
    },
    openImport () {
      document.getElementById('import-file').click()
    },
    async onFileChange(event) {
      let xlsxfile = event.target.files ? event.target.files[0] : null;
      await readXlsxFile(xlsxfile).then(async (rows) => {
        this.passengers = []
        await this.importPassengersFromFile(rows);
        setTimeout(() => {
          event.target.value = null;
        }, 1000);
      })
    },
    openModal (passengers) {
      this.loadPassengers()
      this.$refs['passenger-list'].toggle()
    },
    openPassagengerList (item) {
      this.passengers = []
      this.editing_item = item
      this.list_passanger_title = item.vehicle.name
      this.passengers = item.trip.passengers ? item.trip.passengers.value : []

      this.openModal(this.passengers)
    },
    downloadContract (item) {
      axios({
        method: 'POST',
        responseType: 'blob',
        url: process.env.API_URL + `services/${item.id}/generate-contract`
      })
        .then((response) => {
          FileSaver.saveAs(response.data, `contrato_servico_${item.id}.pdf`)
        })
    },
    getType (type) {
      switch (type) {
        case 'onewaytrip':
          return 'Viagem somente ida'
        case 'roundtrip':
          return 'Viagem ida e volta'
        case 'hourtrip':
          return 'Viagem por hora'
        case 'customtrip':
          return 'Viagem com roteiro personalizado'
        default:
          return 'Viagem'
      }
    },
    dateFormatter: function (date) {
      let year = this.moment(date).format('YYYY')
      let month = this.moment(date).format('MM')
      let day = this.moment(date).format('DD')
      date = new Date(year, (month - 1 < 0 ? 0 : month - 1), day)
      return this.moment(date).format('YYYY/MM/DD')
    },
    getDocumentLabel(documentType) {
      switch (documentType) {
        case 'RG':
          return 'RG'
        case 'CNH':
          return 'CNH'
        case 'PASSAPORTE':
          return 'Passaporte'
        case 'CERTIDÃO':
          return 'Certidão de Nascimento'
        default:
          return 'Documento'
      }
    },
    async importPassengersFromFile(data){
      var passenger;
      var newPassengers = [];
      for(var i = 0; i < data.length; i++) {
          passenger = data[i];
          if(i > 0 && passenger[1] != null ){
            var birthDate = '';
            if(passenger[6]) {
              var actualBirthDate = this.moment(passenger[6]);
              actualBirthDate.add(3, 'hours');
              birthDate = this.moment(actualBirthDate).format('DD/MM/YYYY');
            }
            var passengerData = {
              seat: i,
              name: passenger[1],
              document: passenger[2],
              documentType: this.docEnum[passenger[3]],
              documentIssuer: passenger[4],
              isChild: passenger[5] ? 'yes' : 'no',
              birthDate: birthDate,
            };
           
            newPassengers.push(passengerData)
          }
      }

      if(window.innerWidth > 1024) {
        var emptyPassengers = Array.from({length: (this.seats - newPassengers.length)}, (v, i) => ({
          seat: i,
          name: '',
          document: '',
          documentType: '',
          documentIssuer: '',
          isChild: '',
          birthDate: '',
        }));
        this.passengers = [
          ...newPassengers,
          ...emptyPassengers
        ]
      }else{
        this.passengers = newPassengers
        
      }

      await this.savePassengerList().then((response) => {
        this.$swal('Tudo certo', 'Lista de passageiros atualizada com sucesso!', 'success')
        this.success = true
      });
      
    },

    async savePassengerList(passengersList = null) {
      const orderItemId = this.item.id
      await axios.post(process.env.API_URL + `services/${orderItemId}/passengers/insert`, { passengers: passengersList ? passengersList : this.passengers, orderItemId: orderItemId })
        .then((response) => {
          this.passenger.id = ''
          this.success = true
          this.passenger.document = ''
          this.passenger.name = ''
          this.passenger.documentType = ''
          this.passenger.documentIssuer = ''
          this.passenger.birthDate = ''
          this.passenger.isChild = ''
        })
    },
    passengersModal () {
    
      if (this.passengers) {
        this.passengers.push({
          id: this.passengers.length + 1,
          name: this.passenger.name,
          document: this.passenger.document,
          documentType: this.passenger.documentType,
          documentIssuer: this.passenger.documentIssuer,
          isChild: this.passenger.isChild,
          birthDate: this.passenger.birthDate
        })

      }
    },
    async removePassenger (index, cleanAll = false) {
      let passenger = this.passengers[index]
      try {
        let shouldIRemoveThisPassenger = this.deleteConfirmation === 0 ? await this.showAlert(passenger.name) : true
        if (shouldIRemoveThisPassenger) {
          if (passenger !== undefined && passenger.id !== 0) {
            const orderItemId = this.item.id

            axios.post(process.env.API_URL + `services/${orderItemId}/passenger/${passenger.id}/delete`)
              .then((response) => {
                // this.passengers = response.data.passengers
                if (!cleanAll) {
                  this.success = true
                  this.passengers.splice(index, 1)

                  this.$swal('Tudo certo', 'Passageiro excluído com sucesso!', 'success')
                }
              })
          }
        }
      } catch (error) {
      }

    },
    async editSpecificPassenger(passengerId) {
      let passenger = this.passengers.find(p => p.id === passengerId);
      this.passenger = passenger;
      this.editingPassenger = true;
      this.showPassengersModal();
    },
    async removeSpecificPassenger(passengerId){
      let passenger = this.passengers.find(p => p.id === passengerId);
      let index = this.passengers.indexOf(passenger);
      this.passengers.splice(index, 1)
    },
    loadPassengers () {
      const orderItemId = this.item.id

      axios({
        method: 'GET',
        url: process.env.API_URL + `services/${orderItemId}/passengers/list`
      })
        .then((response) => {
          var info = response.data
          if(info.passengers.length == 0) {

            if(window.innerWidth > 1024) {
              this.passengers = Array.from({length: info.seats}, (v, i) => ({
                seat: i,
                name: '',
                document: '',
                documentType: '',
                documentIssuer: '',
                isChild: '',
                birthDate: '',
                empty: true
              }));
            }
          }else if(info.passengers.length != info.seats) {
            if(window.innerWidth > 1024) {
              var emptyPassengers = Array.from({length: (info.seats - info.passengers.length)}, (v, i) => ({
                seat: i,
                name: '',
                document: '',
                documentType: '',
                documentIssuer: '',
                isChild: '',
                birthDate: '',
              }));
              this.passengers = [
                ...info.passengers,
                ...emptyPassengers
              ]
            }else{
              this.passengers = info.passengers
            }
          }
          else{
            this.passengers = info.passengers
          }
          this.seats = info.seats

          setTimeout(() => {
            this.addEventListenersToBirthDateCells();
          }, 1500);

        })
    },
    checkFormValidity () {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    async cleanEmpties (array) {
      return array.filter((item) => {
        return item.name !== '' && item.document !== '' && item.documentType !== '' && item.documentIssuer !== ''
      })
    },
    async handleOk (bvModalEvt) {

      if(this.passengers.length === 0) {
        this.$swal('Ops!', 'Você precisa adicionar pelo menos um passageiro para salvar a lista.', 'error')
        return
      }

      const formattedPassengers = await this.cleanEmpties(this.passengers);
      await this.savePassengerList(formattedPassengers).then((response) => {
        this.$swal('Tudo certo', 'Lista de passageiros salva com sucesso!', 'success')
        this.passengers = response.data.passengers
        this.success = true
      });
     
        
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  mounted () {

  }
}
</script>
  <style lang="scss">

    .mobile-table {
      min-width: 1600px;

      .actions {
        display: flex;
        justify-content: flex-end;

        .btn {
          
          padding: 5px 10px;
          font-size: 12px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          text-decoration: none;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-transition: all .3s ease;
          transition: all .3s ease;
          border-radius: 5px;
        
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;
          height: 30px;

          &.btn-edit {
            color: var(--default-color);
            border: 1px solid var(--default-color);
            margin-right: 10px;
          }

          &.btn-delete {
            color: #e63e36;
            border: 1px solid #e63e36;
          }
        }
      }
    }

    #notification {
      position: fixed;
      top: -20vh;
      height: auto;
      left: 0px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: .3s ease-in-out;
      z-index: 999999999999999999;

      .notification-body {
        background: #161616d4;
        width: 90vw;
        border-radius: 15px;
        padding: 20px;

        h4 {
          color: white;
          font-size: 16px;
          font-weight: 400;
        }

        p {
          font-size: 14px;
          color: #b5b5b5;
          margin-bottom: 0;
        }

      }
      &.active {
        top: 40px;
      }
    }


    .modal-content .modal-header {
      position: absolute;
      right: 0px;
      top: 0px;
      z-index: 999999999;
    }

    #passengersMobileModal {
      position: relative;
      top: 0;
      z-index: 99;
      left: 0;
      background: white;
      width: 100%;
      height: 100%;
      padding: 20px;
      display: none;

      &.active{
        display: block!important;
      }

     .form {
        padding-top: 30px!important;

        .col-12 {
          display: flex;
          flex-direction: column;
          margin-bottom: 10px;
        }

        label {
          padding-bottom: 05px;
          display: flex;
          font-size: 14px!important;
        }

        input, select {
          padding: 5px 10px;
          border: 1px solid #d1d1d1!important;
          display: flex;
          border-radius: 5px;
        }

      }

      .actions {
        display: flex;
        flex-direction: row!important;
        margin-top: 20px;

        .btn {
          flex: 1;

          &.btn-secondary {
            background: #c7c7c7;
            border-color: #c7c7c7;
          }
          
          &.btn-outline-primary {
            border-color: var(--default-color)!important;
            color: var(--default-color)!important;

            &:hover {
              border-color: var(--default-color)!important;
              background: transparent!important;
              color: var(--default-color)!important;
            }
          }
        }
      }
    }

    .color-primary {
      color: var(--default-color);
    }
    .vue-excel-editor {
      width: 100%!important;
      max-width: 100%!important;

      .component-content {
        width: 100%!important;
        max-width: 100%!important;

        .table-content {
          width: 100%!important;

          table {
            width: 100%!important;
          }
        }
      }
    }
    table {
      border: 1px solid #ccc;
      border-collapse: collapse;
      margin: 0;
      padding: 0;
      width: 100%;
      table-layout: fixed;
      min-width: 900px;
    }

    table caption {
      font-size: 1.5em;
      margin: .5em 0 .75em;
    }

    table tr {
      background-color: #f8f8f8;
      border: 1px solid #ddd;
      padding: .35em;
    }

    table th,
    table td {
      padding: .625em;
      text-align: center;
    }

    table th {
      font-size: .85em;
      letter-spacing: .1em;
      text-transform: uppercase;
    }

    td.empty {
      font-size: 12px;
      color: #a5a5a5;
      font-weight: 300;
  }

  div#vertical-scrolling {
    opacity: 0;
    visibility: hidden;
  }

    .add-passenger {
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 2px solid #d1d1d1;
      padding: 5px 10px;
      border-radius: 3px;
      height: calc(1.5em + 0.75rem + 10px);
      text-transform: uppercase;
      width: 100%;

      * {
          fill: var(--default-color);
          color: var(--default-color);
      }

    }


    .modal-xl {
      width: 80vw !important;
      max-width: 80vw !important;
    }

    .extra-buttons {
        padding-bottom: 30px;
        padding-top: 15px;
        display: flex;
    }

    .btn-outline-primary {
        border-color: var(--default-color)!important;
        color: var(--default-color) !important;
        background: white!important;
        font-size: 16px !important;
        border: 1px solid;
        border-radius: 4px;
        font-weight: 500 !important;
        margin-left: 10px;

        &:hover {
        background: var(--default-color)!important;
        color: white !important;
        }
    }

    .btn-primary {
        border-color: var(--default-color);
        color: white !important;
        background: var(--default-color);
        font-size: 16px !important;
        border: 1px solid;
        font-weight: 500 !important;
        border-radius: 4px;
    }

    
    .passenger-import-card {
      border: 1px solid var(--default-color);
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;

      p {
        color: var(--default-color);
        font-size: 14px;
      }
    }

    .passenger-import-card .btn-action {
      color: var(--default-color);
      padding: 5px 10px;
      font-size: 12px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      text-decoration: none;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      border-radius: 5px;
      border: 1px solid var(--default-color);
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      height: 30px;

      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }

      &.import {
        color: white;
        background-color: var(--default-color);

        * {
          fill: white;
        }
      }

      svg {
        margin-left: 5px;
      }

      * {
        color: var(--default-color);
        fill: var(--default-color);
      }
      
    }

    @media (max-width: 1024px) {
      .modal-footer .row {
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .modal-footer button {
        font-size: 12px!important;
        padding: 10px 0!important;
      }
      .modal-footer button:first-child {
        flex: 5;
      }
      .modal-footer button:last-child {
        flex: 15;
      }
    }

  </style>
