<template>
  <div class="card card-payment-info" :style="cssProps">

      <div class="col-12">

      <!-- <div class="card-header">

        <a v-if="!details_visible" href="#" @click.prevent="details_visible = !details_visible">Ver mais detalhes  <font-awesome-icon :icon="['fas', 'angle-down']"></font-awesome-icon></a>
        <a v-else href="#" @click.prevent="details_visible = !details_visible">Ocultar detalhes  <font-awesome-icon :icon="['fas', 'angle-up']"></font-awesome-icon></a>
      </div> -->
      <h4 class="mt-2">Faturas</h4>
      <div class="card-body">
        <div class="payment-info-title-wrapper">
          <span v-if="order.payment.method === 'credit_card'">
            <font-awesome-icon :icon="['fa', 'credit-card']" /> <strong style="margin-left:10px;">CARTÃO DE CRÉDITO: **** **** **** {{formatCardNumber()}}</strong>
          </span>
        </div>

        <div class="payment-info-wrapper d-xl-flex d-none w-100">
          <div v-if="order.payment.method === 'bank_slip_parcel' || order.payment.method === 'bank_slip_parcel_2'" class="w-100">
            <div class="row row-payment-info-header">
              <div class="col-2">
                vencimento
              </div>

              <div class="col-2">
                status
              </div>

              <div class="col-2">
                valor
              </div>

              <div class="col-1">
                parcela
              </div>

              <div class="col-5 payment-header">
                pagamento
              </div>

            </div>

            <div class="payment-infos-wrapper">
              <div class="payment-info" v-for="(charge, index) in order.payment.paymentInfo.charges" :key="index">
                <div class="row payment-info-row align-items-center">
                  <div class="col-2 col-payment-info-installment">
                    {{ charge.json_data.dueDate | date }}
                  </div>

                  <div class="col-2 col-payment-info-installment">
                    {{ filterPaymentStatus(charge.json_data.status) }}
                  </div>

                  <div class="col-2 col-payment-info-installment">
                    {{ charge.json_data.value | currency }}
                  </div>

                  <div class="col-1 col-payment-info-installment">
                    {{ index + 1 }}/{{ order.payment.paymentInfo.charges.length }}
                  </div>

                  <div class="col-3 col-payment-info-installment d-flex justify-content-end">
                    <a :href="charge.json_data.bankSlipUrl" class="download-bank-slip" target="_blank">Pagar com Boleto <font-awesome-icon :icon="['fas', 'cloud-download-alt']" /></a>
                  </div>

                  <div class="col-2 text-left justify-content-start col-payment-info-installment">
                      <div class="pix-payment" v-on:click="showPixModal(charge)">Pagar com PIX <img
                        src="../../assets/img/pix.svg"
                        alt=""
                        style="max-width: 100%"
                      /></div>
                  </div>    

                </div>
              </div>
            </div>
          </div>

          <div v-if="order.payment.method === 'bank_slip'" class="w-100">
            <div class="row row-payment-info-header d-none d-xl-flex px-3" style="overflow: auto;">
              <div class="col-2 text-left justify-content-start">
                vencimento
              </div>

              <div class="col-2 text-left justify-content-start">
                status
              </div>

              <div class="col-2 text-left justify-content-start">
                valor
              </div>

              <div class="col-1 text-left justify-content-start">
                parcela
              </div>

              <div class="col-5 ps-4 text-center justify-content-center payment-header">
                pagamento
              </div>
            </div>

            <div class="payment-infos-wrapper d-none d-xl-flex">
              <div class="payment-info">
                <div class="row payment-info-row">
                  <div class="col-2 text-left justify-content-start col-payment-info-installment">
                    {{ order.payment.paymentInfo.duedate | date }}
                  </div>

                  <div class="col-2 text-left justify-content-start col-payment-info-installment">
                    {{ filterPaymentStatus(order.payment.paymentInfo.charges[0].json_data.status) }}
                  </div>

                  <div class="col-2 text-left justify-content-start col-payment-info-installment">
                    {{ order.total | currency }}
                  </div>

                  <div class="col-1 text-left justify-content-start col-payment-info-installment">
                    1 / 1
                  </div>

                  <div class="col-3 d-flex justify-content-end col-payment-info-installment">
                      <a :href="order.payment.paymentInfo.charges[0].json_data.bankSlipUrl" class="download-bank-slip" target="_blank">Pagar com Boleto <font-awesome-icon :icon="['fas', 'cloud-download-alt']" /></a>
                  </div>

                  <div class="col-2 text-left justify-content-start col-payment-info-installment">
                      <div class="pix-payment" v-on:click="showPixModal(order.payment.paymentInfo.charges[0])">Pagar com PIX <img
                        src="../../assets/img/pix.svg"
                        alt=""
                        style="max-width: 100%"
                      /></div>
                  </div>               

                </div>
              </div>
            </div>

            <div class="d-xl-none d-flex">
              <div class="mobile-payment-info flex-column d-flex">
                  <label for="">Vencimento</label>
                  <p>{{ order.payment.paymentInfo.duedate | date }}</p>
                  <label for="">Status</label>
                  <p>{{ filterPaymentStatus(order.payment.paymentInfo.charges[0].json_data.status) }}</p>                  
                  <label for="">Valor</label>
                  <p>{{ order.total | currency }}</p>
                  <label for="">Parcela</label>
                  <p>1 / 1</p>
                  <label for="">Cobrança</label>
                  <p class="mb-0"> <a :href="order.payment.paymentInfo.link" class="download-bank-slip" target="_blank">Pagar com Boleto <font-awesome-icon :icon="['fas', 'cloud-download-alt']" /></a> </p>
                  <p>
                    <div class="pix-payment" v-on:click="showPixModal(order.payment.paymentInfo.charges[0])">Pagar com PIX <img
                        src="../../assets/img/pix.svg"
                        alt=""
                        style="max-width: 100%"
                      /></div>
                  </p>
              </div>
            </div>

          </div>
        </div>


        <div class="d-xl-none d-flex flex-column">
          <div v-if="order.payment.method === 'bank_slip_parcel' || order.payment.method === 'bank_slip_parcel_2'">
            <div class="mobile-payment-info flex-column d-flex mb-3"  v-for="(charge, index) in order.payment.paymentInfo.charges" :key="index">
              <label for="">Vencimento</label>
              <p>{{ charge.json_data.dueDate | date }}</p>
              <label for="">Status</label>
              <p> {{ filterPaymentStatus(charge.json_data.status) }}</p>
              <label for="">Valor</label>
              <p> {{ charge.json_data.value | currency }}</p>
              <label for="">Parcela</label>
              <p> {{ index + 1 }}/{{ order.payment.paymentInfo.charges.length }}</p>
              <label for="">Cobrança</label>
              <p class="mb-0"><a :href="charge.json_data.bankSlipUrl" class="download-bank-slip btn btn-info w-100" target="_blank">Pagar com Boleto <font-awesome-icon :icon="['fas', 'cloud-download-alt']" /></a></p>
              <p>
                <div class="pix-payment" v-on:click="showPixModal(order.payment.paymentInfo.charges[0])">Pagar com PIX <img
                    src="../../assets/img/pix.svg"
                    alt=""
                    style="max-width: 100%"
                  /></div>
              </p>
            </div>
          </div>

          <div v-if="order.payment.method === 'bank_slip'">
            <div class="mobile-payment-info flex-column d-flex mb-3"  v-for="(charge, index) in order.payment.paymentInfo.charges" :key="index">
              <label for="">Vencimento</label>
              <p>{{ order.payment.paymentInfo.duedate | date }}</p>
              <label for="">Status</label>
              <p>{{ filterPaymentStatus(order.payment.paymentInfo.charges[0].json_data.status) }}</p>         
              <label for="">Valor</label>
              <p>{{ order.total | currency }}</p>
              <label for="">Parcela</label>
              <p>1 / 1</p>
              <label for="">Cobrança</label>
              <p class="mb-0"><a :href="charge.json_data.bankSlipUrl" class="download-bank-slip btn btn-info w-100" target="_blank">Pagar com Boleto <font-awesome-icon class="icon alt" :icon="['fas', 'cloud-download-alt']" /></a></p>
              <p>
                <div class="pix-payment" v-on:click="showPixModal(order.payment.paymentInfo.charges[0])">Pagar com PIX <img
                    src="../../assets/img/pix.svg"
                    alt=""
                    style="max-width: 100%"
                  /></div>
              </p>
            </div>
          </div>
          

        </div>

        <!-- <div class="payment-info-resume">
          <div class="order-resume-table">
            <div class="order-resume-row">
              <div class="col-title">
                Total
              </div>
              <div class="col-value">
                {{ order.total | currency }}
              </div>
            </div>
          </div>
        </div> -->

        <!-- <transition name="fade">
          <div class="slide-out my-4" v-show="details_visible" style="width:100%">
            <div class="row">
              <div class="col-md-5">
                <div class="detail-title">Status do Pagamento</div>
                <p class="my-2"><span class="payment-status" v-bind:style="{background: this.order.orderStatusColor}">{{this.order.orderStatus}} </span></p>
                <div class="detail-title">Forma de Pagamento</div>
                <p>{{getPaymentMethod(this.order.payment.method)}}</p>
                <div class="detail-title">Data do pedido</div>
                <p>{{this.order.orderDate | datetime}}</p>
              </div>
              <div class="col-md-7">
                <div class="detail-title">Detalhamento</div>
                <p class="detail-text"><span>Subtotal:</span> {{order.subtotal | currency}}</p>
                <p class="detail-text" style="color:#0a9142;" v-if="order.coupon"><span>Cupom: </span> -{{order.discount | currency}}</p>
                <hr>
                <h5 class="detail-text"><span>Total:</span> {{order.total | currency}}</h5>
              </div>
            </div>
          </div>
        </transition> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderPaymentDetails',
  props: ['order'],
  data () { return {} },
  methods: {
    getPaymentMethod (val) {
      switch (val) {
        case 'bank_slip':
          return 'Boleto'
        case 'bank_slip_parcel':
          return 'Boleto Parcelado'
        case 'credit_card':
          return 'Cartão de Crédito'
        default:
          break
      }
    },
    filterPaymentStatus (val) {
      switch (val) {
        case 'PENDING':
          return 'Pendente'
      }

      return val
    },
    copyBankSlipBarCode (e) {
      const containerBarCode = e.target.parentNode.parentNode.classList.contains('bank-slip-barcode-wrapper')
        ? e.target.parentNode.parentNode
        : e.target.parentNode.parentNode.parentNode

      containerBarCode.querySelector('input').select()
      document.execCommand('copy')
    },
    formatCardNumber () {
      return this.order.payment.paymentInfo.credit_card_four_last_digits.value
    },
    displayPixInformation(charge) {
      var output = '';
      output += '<div class="pix-payment-info">';
      output += '<p class="qr-code"><img src="'+charge.pix_data.encodedImage
        +'"></p>';
      output += '<div class="copy-paste-area">' +
         '<p class="content">' + charge.pix_data.payload + '</p>' +
      '</div>';
      return output;
    },
    copyToClipboard(text) {
      var input = document.createElement('textarea');
      input.innerHTML = text;
      document.body.appendChild(input);
      input.select();
      var result = document.execCommand('copy');
      document.body.removeChild(input);
      return result;
    },
    showPixModal(charge) {
      this.$swal({
        title: 'Pagamento via Pix!',
        html: this.displayPixInformation(charge),
        showDenyButton: false,
        showConfirmButton: true,
        confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
        confirmButtonText: 'Copiar código PIX',
        iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
        icon: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.copyToClipboard(charge.pix_data.payload);
          this.$swal({
            title: 'Código copiado com sucesso!',
            icon: 'success',
            timer: 2500,
            confirmButtonColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            iconColor: this.$store.state.companyDetails.whitelabel.body_font_color,
            confirmButtonText: 'Fechar'
          })
        }
      })
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">

  .payment-header {
    padding-left: 80px!important;
  }
  .pix-payment {
    padding: 5px 10px;
    font-size: 12px;
    color: white!important;
    display: flex;
    background: var(--default-color);
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid var(--default-color);

    @media screen and (max-width: 1024px) {
      text-transform: uppercase;
      padding: 10px 10px;
      font-size: 14px;
    }


    &:hover {
      cursor: pointer;
      opacity: .8;
    }
    a {
      color: white;
    }
  }

  .pix-payment img {
    height: 18px;
    margin-left: 10px;
  }

  .card-payment-info {
    padding: 30px 0!important;
    .card-body {
      padding: 0!important;
    }
  }

  .qr-code img {
    height: 240px;
  }

  .copy-paste-area .content {
    font-size: 12px;
    line-break: anywhere;
    margin: 10px;
    padding: 10px;
    background: #ebebeb;
    border-radius: 10px;
    color: #5b5b5b;
}

  .mobile-payment-info {
      width: 100%!important;
      padding: 10px;
      border: 1px solid var(--default-color);
      border-radius: 5px;
      font-size: 18px;
      color: black;

      label {
        font-size: 16px;
        font-weight: bold;
        color: var(--default-color);;
      }
  }

  .card-body {
    padding: 30px 0!important;
  }

  .bank-slip-barcode-wrapper {
    display: flex;
    // width: 60%;
  }

  .bar-code-input {
    background: #e8e8e8;
    width: 430px;
    outline: none;
    border: none;
  }

  .row-payment-info-header {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    font-weight: bold;
    padding-bottom: 10px!important;
    padding-top: 10px!important;
    font-size: 12px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .payment-info {
    text-align: center;
    margin-bottom: 10px;
    background-color: #fffcfc!important;
    border-radius: 5px;
    border: 1px solid;
    border-color: var(--default-color);
    width: 100%;

    * {
      color: var(--default-color)
    }

    // &:hover {
    //   background-color: var(--default-color)!important;
    //    * {
    //      color: white!important;
    //      text-decoration: none!important;
    //    }
    // }

    .payment-info-row {
      padding-top: 15px;
      padding-bottom: 15px;
      margin-left: 0;
      margin-right: 0;
    }

    .collapse {
      margin-top: 0;

      .card {
        border: none;
        background: transparent;
        padding: 0 10px;
        margin-bottom: 0;

        .card-body {

          .payment-details {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }

  .order-resume-row {
    display: flex;
    justify-content: space-between;
    padding: 0.75rem;
  }

  .copy-barcode-button {
    padding: 0;
    background: transparent;
    border: none;
    color: var(--default-color);
    margin-left: 10px;
    font-size: 20px;
    line-height: 1;

    &:hover {
      text-decoration: none;
      color: var(--default-color);
    }
  }

  .download-bank-slip {
    color: var(--default-color);
    padding: 5px 10px;
    font-size: 12px;
    display: flex;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    transition: all .3s ease;
    border-radius: 5px;
    border: 1px solid var(--default-color);
    width: fit-content;

    &:hover {
      opacity: .8;
    }

    svg {
      margin-left: 10px!important;
    }

    @media (max-width: 1024px) {
      background-color: transparent!important;
      color: var(--default-color)!important;
      border-color: var(--default-color)!important;

      svg {
        
        * {
          fill: var(--default-color)!important;
        }
        
      }
    }

    &:hover {
      text-decoration: none;
      color: var(--default-color);
    }
  }

  @media(max-width: 767px) {
    .container-order-payment {
      margin-top: 25px;
    }

    .payment-info-title-wrapper {
      text-align: center;
      margin-bottom: 30px !important;
    }

    .payment-info-wrapper {
      overflow: auto;
    }

    .row-payment-info-header {
      width: 200%;
      overflow: auto;
    }

    .payment-infos-wrapper {
      width: 200%;
    }
  }
</style>
