<template>
  <div class="w-100">
      <div class="f-card payment-order-details">
        <h3>Resumo do pedido</h3>
        <div class="row">
          <div class="col-md-12 p-0">
            <div v-for="(item, index) in order.items" :key="index" >
              <order-trip-item :order="order" :item="item" :index="index" :showPassangerList="true"></order-trip-item>
            </div>
          </div>
          <div class="col-xl-12">
            <div class="content order-payment-list d-flex justify-content-between flex-column">
              <h4>{{order.items.length == 1 ? 'Veículo' : 'Veículos'}}</h4>
              <div class="d-flex flex-column order-vehicle mb-4" v-for="(item, index) in order.items" :key="index">
                <div class="order-payment-item">
                  <!-- {{item}} -->
                  <div class="label"><strong>{{formatTitleVehicle(item.vehicle.name)}}</strong> - <span class="type">{{getType(item.type)}}</span></div>
                  <div class="value">{{( ( parseFloat(item.vehicle.priceCents) + getExtras(order) ) ) | currency}}</div>

                </div>

                <div class="order-payment-item" v-if="driverExpensesCost(item) > 0 && item.trip.driverExpenses.paymentType == 'paynow'">
                  <div class="label">Despesas do motorista</div>
                  <div class="value">{{driverExpensesCost(item) | currency}}</div>
                </div>

                <div v-if="order.coupon && order.coupon.percent_off" class="cupom-details">
                  <div class="order-payment-item">
                    <div class="label">Desconto</div>
                    <div class="value">{{( item.vehicle.priceCents * order.coupon.percent_off) / 100  | currency}}</div>
                  </div>
                </div>

                <div class="order-payment-item">
                  <div class="label">Total</div>
                  <div class="value">{{(parseFloat(item.vehicle.priceCents) + parseFloat(driverExpensesCost(item)) + getExtras(order)) | currency}}</div>
                </div>

              </div>
              <div v-if="order.coupon">
                <h4>Desconto</h4>
                <div class="order-payment-item d-flex">
                  <div class="label">Cupom aplicado</div>
                  <div class="value">{{order.coupon.name}}</div>
                </div>
                <div class="order-payment-item d-flex">
                  <div class="label">Valor do desconto</div>
                  <div class="value total">{{ order.discount | currency}}</div>
                </div>
              </div>

              <div class="order-payment-item">
                <div class="label">Total</div>
                <div class="value total">{{order.total | currency}}</div>
              </div>

            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <a href="#" @click.prevent="downloadContract(order.id)" class="btn btn-outline-primary order-item-action generate-contract mt-3">Baixar contrato</a>
          </div>
        </div>
      </div>
      <div :class="[true ? 'single-itinerary col-12' : '', orderSummary.showCustomRouter ? 'active' : 'inactive']" @click.prevent="closeItinerary()">
        <div class="content">
          <h3>Resumo do roteiro</h3>
          <itinerary-summary :data="orderSummary.customRouter"></itinerary-summary>
        </div>
      </div>
  </div>
</template>

<script>
import OrderTripItem from '../general/OrderTripItem.vue'
import ItinerarySummary from '../modals/CustomTripRoute/ItinerarySummary.vue'
import axios from 'axios'
import FileSaver from 'file-saver'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderSummary',
  props: ['order'],
  components: {
    OrderTripItem,
    ItinerarySummary
  },
  data () { return {
  } },
  methods: {
    closeItinerary (){
      this.$store.commit('SET_ORDER_SUMMARY', {
        key: 'showCustomRouter',
        value: false
      })

    },
    driverExpensesCost (item) {
      if(item.trip.driverExpenses && item.trip.driverExpenses.paymentType == 'paynow') {
        return item.metas.driverExpenses ? item.metas.driverExpenses : ( item.trip.driverExpenses ? item.trip.driverExpenses.driverExpensesValue : 0)
      }else{
        return 0
      }
    },
    getExtras(order){
      let extras = 0
      if(order.addition) {
        extras += parseFloat(order.addition)
      }
      if(order.deduction) {
        extras -= parseFloat(order.deduction)
      }
      extras = (parseFloat(extras) / parseInt(order.items.length));
      return  extras
    },
    formatTitleVehicle (title) {
      // return title ? title.split(' ').slice(1).join(' ') : ''
      return title
    },
    openPassagengerList (item) {
      this.passengers = []
      this.editing_item = item
      this.list_passanger_title = item.vehicle.name
      this.passengers = item.trip.passengers ? item.trip.passengers.value : []

      this.$emit('open-modal', this.passengers)
    },
    dateFormatter: function (date, format) {
      return this.moment(date).format(format)
    },
    downloadContract (order_id) {
      axios({
        method: 'POST',
        responseType: 'blob',
        data: {
          order_id: order_id
        },
        url: process.env.API_URL + `services/generate-contract`
      })
        .then((response) => {
          FileSaver.saveAs(response.data, `contrato_servico_${order_id}_${this.moment().format('d-m-Y-hh-mm-ss')}.pdf`)
        })
    },
    getType (type) {
      switch (type) {
        case 'onewaytrip':
          return 'Viagem somente ida'
        case 'roundtrip':
          return 'Viagem ida e volta'
        case 'hourtrip':
          return 'Viagem por hora'
        case 'customtrip':
          return 'Viagem com roteiro personalizado'
        default:
          return 'Viagem'
      }
    }
  },
  computed: {
    ...mapGetters({
      orderSummary: 'getOrderSummary'
    }),
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss">
    .payment-order-details {

      .btn-outline-primary {
        border-color: var(--default-color);
        color: var(--default-color) !important;
        font-size: 16px !important;
        border: 1px solid;
        border-radius: 4px;
        font-weight: 500 !important;

        &:hover {
          background: var(--default-color);
          color: white !important;
        }
      }

      .btn-primary {
        border-color: var(--default-color);
        color: white !important;
        background: var(--default-color);
        font-size: 16px !important;
        border: 1px solid;
        font-weight: 500 !important;
        border-radius: 4px;
      }

      .type {
        font-size: 14px;
      }

      &.card {
        &.card-page-header {
          margin-top: 60px;
        }
      }

      h3 {
        font-size: 24px;
        margin-bottom: 30px;
      }

      h4 {
        margin-top: 10px;
        font-size: 16px;
        color: var(--default-color);
        font-family: Poppins,sans-serif;
      }
      .order-payment-item {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 1px dashed #d9d9d9;
        color: #373737;
        font-size: 16px;

        .label {
          font-weight: 400;

          strong {
            font-weight: 500;
          }
        }

        .value {
          min-width: 100px;
          display: flex;
          align-items: center;
        }

        &.totals {
          font-size: 18px;
        }

        .value.total {
          font-weight: 600
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

    .order-vehicle {
      background: #f7f7f7;
      margin: 5px 0;
      padding: 10px;
      border-radius: 5px;
    }

    }
</style>
