<template>
  <div class="row row-trip-info w-100">
    <div class="col-12 mt-4">
      <h4 class="trip-info-title w-100 d-flex justify-content-between">
        <div class="title">
          Viagem de ida - {{ originDate | date }}
        </div>
      </h4>
      <div class="usage-item align-items-start xl-row mb-4 w-100">
        <div :class="[true ? 'col-origin m-xl-0 pr-5' : '', destination ? 'col-md-5' : 'col-md-10']">
          <h4 class="trip-info-title">Origem</h4>
          <div class="trip-info">
            <b-icon-geo-alt></b-icon-geo-alt>
            {{ origin }}
          </div>
          <div class="trip-info">
            <b-icon-calendar3></b-icon-calendar3>
            {{ originDate | date_result_list }}
          </div>
          <div class="trip-info">
            <b-icon-clock></b-icon-clock>
            {{ originTime }}
          </div>
        </div>

        <div class="col-destination col-md-5 p-xl-0" v-if="destination">
          <h4 class="trip-info-title">Destino</h4>
          <div class="trip-info">
            <b-icon-geo-alt></b-icon-geo-alt>
            {{ destination }}
          </div>
          <!-- <div class="trip-info" v-if="destinationDate">
            <b-icon-calendar3></b-icon-calendar3>
            {{ destinationDate | date_result_list }}
          </div>
          <div class="trip-info" v-if="destinationTime">
            <b-icon-clock></b-icon-clock>
            {{ destinationTime }}
          </div>
          <div class="trip-info" v-if="franchise">
            <b-icon-clock></b-icon-clock>
            Uso do veículo por {{ franchise }} horas
          </div> -->
        </div>
      </div>
    </div>

    <div class="col-12 mt-4">
      <h4 class="trip-info-title w-100 d-flex justify-content-between">
        <div class="title">
          Viagem de volta - {{ destinationDate | date }}
        </div>
      </h4>
      <div class="usage-item align-items-start xl-row mb-4 w-100">
        <div :class="[true ? 'col-origin m-xl-0 pr-5' : '', destination ? 'col-md-5' : 'col-md-10']">
          <h4 class="trip-info-title">Origem</h4>
          <div class="trip-info">
            <b-icon-geo-alt></b-icon-geo-alt>
            {{ destination }}
          </div>
          <div class="trip-info">
            <b-icon-calendar3></b-icon-calendar3>
            {{ destinationDate | date_result_list }}
          </div>
          <div class="trip-info">
            <b-icon-clock></b-icon-clock>
            {{ destinationTime }}
          </div>
        </div>

        <div class="col-destination col-md-5 p-xl-0" v-if="destination">
          <h4 class="trip-info-title">Destino</h4>
          <div class="trip-info">
            <b-icon-geo-alt></b-icon-geo-alt>
            {{ origin }}
          </div>
          <!-- <div class="trip-info" v-if="destinationDate">
            <b-icon-calendar3></b-icon-calendar3>
            {{ destinationDate | date_result_list }}
          </div>
          <div class="trip-info" v-if="destinationTime">
            <b-icon-clock></b-icon-clock>
            {{ destinationTime }}
          </div>
          <div class="trip-info" v-if="franchise">
            <b-icon-clock></b-icon-clock>
            Uso do veículo por {{ franchise }} horas
          </div> -->
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import utils from '@/components/mixins/utils'
import ItinerarySummary from '../../modals/CustomTripRoute/ItinerarySummary.vue'

export default {
  name: 'OrderTripAddress',
  props: ['vehicle', 'extra', 'route', 'item'],
  components: { ItinerarySummary },
  mixins: [ utils ],
  data () {
    return {
      roundTrip: this.isRoundTrip(),
      quotes: this.$store.state.daily.quotes,
      busIcon: require('../../../assets/icon/bus.svg'),
      dailysQuote: '',
      extraTripDetails: this.extraData(),
      showItinerary: false,
      randomKey: Math.round(Math.random() * 1000),
      mustShowItinerary: this.$store.state.customRouter.active || this.item.trip.customRouter.active,
      dataRefer: this.item.trip,
      customRouter: [],
    }
  },
  computed: {
    franchise() {
      var franchise = 0;
      if(this.route == 'checkout') {
        franchise = this.item.trip.franchise;
      }else{
        franchise = this.$store.state.simpleTrip.franchise;
      }

      return franchise > 0 ? franchise : false;
    },
    tripTypeName(){
        return this.item.type == 'customtrip' ? 'com roteiro personalizado' : this.item.type == 'hourtrip' ? 'por hora' : this.item.type == 'hourtrip' == 'roundtrip' ? 'ida e volta' : 'somente ida';
    },
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    showTripTypeActions () {
      return this.$store.state.tripType == 'customtrip' && this.route != 'checkout' || this.item.type == 'customtrip' ? true : false;
    },
    origin () {
      return this.getTripInformation('origin', 'address');
    },
    originTime () {
      var time = this.getTripInformation('origin', 'time');
      return time ? time : '00:00'
    },
    originDate () {
      return this.getTripInformation('origin', 'date');
    },
    destination () {
      return this.getTripInformation('destination', 'address');
    },
    destinationTime () {
      return this.getTripInformation('destination', 'time');
    },
    destinationDate () {
      var date = this.getTripInformation('destination', 'date');
      return date != '' ? date : false;
    },
    getStopPoints () {
      return this.$store.state.simpleTrip.stop_points
    },
    getUsageDestination () {
      return this.$store.state.tripDetails.useVehicle
    }
  },
  methods: {
    closeItinerary() {
      this.showItinerary = false;
    },
    extraData() {
      if(!this.$store.state.transfersGoing.isChecked || this.$store.state.serviceType != 'transfer'){
        return false;
      }else{
        return this.extra.transfer_state === 'origin' ? 'Veículo da ida' : 'Veículo da volta'
      }
    },
    isRoundTrip() {

      if(this.route == 'checkout') {
        return this.item.trip.isRoundTrip ? true : false;
      }else{
        return this.$store.state.simpleTrip.is_round_trip ? true : false;
      }
     
    },
    displayItinerary () {
      this.randomKey = Math.round(Math.random() * 1000);
      this.customRouter = this.route == 'checkout' && this.item.type == 'customtrip' ? this.item.trip.customRouter.days : this.$store.state.tripType == 'customtrip' ? this.$store.state.customRouter.days : false;
      this.showItinerary = true;
    },
    getTripInformation(source, field) {
      let output = '';

      if(this.item.type == 'customtrip'){
        let routeIndex = source == 'destination' ? this.item.itinerary.days[0].routes.length - 1 : 0;
        if(field == 'date'){
          output = this.item.itinerary.days[0].routes[routeIndex][field];
          output = this.moment(output).format('YYYY/MM/DD');
        }else{
          output = this.item.itinerary.days[0].routes[routeIndex][source][field];
        }
      }
      else {
        output = this.item.trip[source][field]
      }

      return output;
    },
    formatTitleVehicle (title) {
      // return title ? title.split(' ').slice(1).join(' ') : ''
      return title
    },
    getVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'bus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro'
        case 'carro' :
          return 'car'
        default:
          return 'bus'
      }
    },
  },
  mounted() {

  },
  beforeMount () {
  }
}
</script>

<style lang="scss" scoped>

  .row-trip-info {
    .btn-small {
      padding: 2px 30px;
      border-radius: 4px;
    }
    .btn-outline-primary {
      background-color: white;
      color: var(--default-color)!important;
      border-color: var(--default-color)!important;
      &:hover {
        color: white!important;
        background-color: var(--default-color)!important;
        cursor: pointer;
      }
    }
  }

  .single-itinerary {
    
    display: none;
    .content {
      background: white;
      padding: 30px;
      border-radius: 10px;
      right: -10px;
      position: relative;
      padding-right: 40px;
      border: 1px solid;
      border-color: var(--default-color);
      width: 30vw;
      max-height: 80vh;
      overflow: auto;

      &::-webkit-scrollbar {
          width: 16px!important;
          height: 6px!important;
      }
      &::-webkit-scrollbar-thumb {
          background-color: rgba(0, 0, 0, 0.184);
      }
      &::-webkit-scrollbar-track {
          background: transparent;
      }


      h3 {
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        color: var(--default-color);
      }

      @media(max-width: 980px) {
      width: 100vw;
      }
    }

    &.active {
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      z-index: 999999999;
      background: #00000038;
      padding: 0;
      width: 100vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media(max-width: 980px) {
        left: 0;
      }
    }
  }

  #search-results  {
    #result-list  {
      .result-item  {

        .row-actions {
          .col-actions {
            .book-button {
                border-color: var(--default-color);
                color: white!important;
                background-color: var(--default-color);
                border-radius: 4px;
            }
          }
        }

        .first-row {
          padding: 30px 40px;
          @media (max-width: 1024px) {
            padding: 15px;
          }
        }

        .row-stop-points {
          .col-md-5 {
           
          }
        }
        .col-info {
          .configurations-wrapper {
            padding-top: 5px;

            span {
              background: transparent;
              color: var(--default-color);
              margin-right: 5px;
              padding: 0 5px;
              margin-bottom: 5px;
              display: flex;
              border-color: var(--default-color);
              border: 1px solid;
              border-radius: 5px;
            }
            p {
              text-align: left;
              margin-bottom: 0;
              font-size: 14px;
              color: #000;
              text-transform: capitalize;
              display: flex;
              flex-wrap: wrap;
            }
            .configurations {
              padding-top: 10px;
            }
          }
        }
        .configurations {
          span {

          }
        }
        .col-price  {
          .pricePerSeat {
            span {
              font-size: 14px;
              font-weight: 500;
              text-align: center;
            }
          }
          .more-info  {
            a {
              background-color: var(--default-color);
              border-color: var(--default-color);
              color: white;
            }
          }
        }
      }
    }
  }

</style>
