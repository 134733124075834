<template>
<div class="result-item result-expanded mb-3" :style="cssProps" :id="'vehicle-'+index">

    <div class="first-row row m-0 w-100">
      <div class="col-12 d-flex flex-row align-items-center vehicle-information">
        <div class="col-image">
          <img  :src="item.vehicle.image || item.vehicle.thumbnail" class="vehicle-image">
        </div>
        <div class="col-info">
          <h3 class="title">{{ item.vehicle.title || item.vehicle.name }}</h3>
          <div class="configurations-wrapper" v-if="(item.vehicle.configurations.length > 0 || item.vehicle.air_conditioning || item.vehicle.bathroom)">
            <p class="font-weight-bold">Configurações:</p>
            <p class="configurations">
              <span v-if="item.vehicle.air_conditioning">
                Ar condicionado
              </span>
              <span v-if="item.vehicle.bathroom">
                Banheiro
              </span>

              <span v-for="(config, index) in item.vehicle.configurations" :key="index">
                {{config}}
              </span>
            </p>
          </div>
        </div>

        <div class="col-price text-right">

          <div class="price w-100">
            <span class="value">{{ this.calculateTotals() | currency }}</span>
          </div>

        </div>

      </div>
    </div>

    <order-trip-address :item="item" v-if="!item.metas.priceData || !item.metas.priceData.usingTwoTrips" route="checkout"/>
    <order-trip-split-address :item="item" v-else route="checkout"/>

    <div class="row row-trip-info w-100" v-if="item.trip.driverExpenses.driverExpensesValue > 0 && !item.metas.priceData.usingTwoTrips">
      <div class="col-12 mt-4">
        <h4 class="trip-info-title w-100 d-flex justify-content-between">
          <div class="title">
            Despesas com motorista
          </div>
         </h4>
         <div class="usage-item align-items-center row mb-4 w-100">
          <div class="col-lg-9 col-12">
            {{ this.getDriverExpensesTitle() }}
          </div>
          <div class="col-lg-3 col-12 d-flex align-items-lg-end align-items-start mt-2 mt-lg-0">
            {{ (item.trip.driverExpenses.driverExpensesValue) | currency }}
          </div>
         </div>
      </div>
    </div>

    <order-extra-details :order="order" :item="item" />

  </div>
</template>

<script>

import OrderTripAddress from '../search-results/TripDetails/OrderTripAddress.vue'
import OrderTripSplitAddress from '../search-results/TripDetails/OrderTripSplitAddress.vue'
import OrderExtraDetails from '../../components/order/OrderExtraDetails.vue'

export default {
  name: 'OrderTripItem',
  props: ['item', 'index', 'order'],
  data () {
    return {
      isVisible: true,
      visible: true,
      gallery: [],
      img: '',
      indexImg: 0,
      driverExpensesTitle: '' // this.getDriverExpensesTitle()
    }
  },
  computed: {
    cssProps () {
      return {
        '--default-color': this.$store.state.companyDetails.whitelabel.body_font_color
      }
    },
    primaryColor () {
      return this.$store.state.companyDetails.whitelabel.primaryColor
    },
    secondaryColor () {
      return this.$store.state.companyDetails.whitelabel.secondaryColor
    }
  },
  components: { OrderTripAddress, OrderExtraDetails, OrderTripSplitAddress },
  methods: {
    usageVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },

    calculateTotals () {
      let total = 0
      let $scope = this
      total += this.item.vehicle.priceCents
      total += 0

      var extras = this.getExtras(this.order);
      return parseFloat(total) + extras
    },
    getExtras(order){
      let extras = 0
      if(order.addition) {
        extras += parseFloat(order.addition)
      }
      if(order.deduction) {
        extras -= parseFloat(order.deduction)
      }
      extras = (parseFloat(extras) / parseInt(order.items.length));
      return  extras
    },
    getDriverExpensesTitle () {
      return this.item.trip.driverExpenses.payDriverExpenses === 'paynow'
        ? 'Quero pagar agora e não me preocupar com isso durante a viagem'
        : 'Me responsabilizo por providenciar e pagar durante a viagem'
    },
    usageVehicleType (type) {
      switch (type) {
        case 'onibus':
          return 'onibus'
        case 'van':
          return 'van'
        case 'micro-onibus':
          return 'micro_onibus'
        case 'carro' :
          return 'carro'
        default:
          return 'onibus'
      }
    },
    removeCartItem () {
      this.$store.commit('cart/removeItem', this.index)
      this.$store.commit('cart/calculateCartTotal', { data: this.$store.state })
    },
    showVehicleDetails () {
      this.isVisible = true
    },
    hideVehicleDetails () {
      this.isVisible = false
    },
    show () {
      this.visible = true
    },
    handleHide () {
      this.visible = false
    },
    showSingle (index) {
      this.img = this.gallery[index]
      this.show()
    }
  },
  mounted () {

  }
}
</script>

<style lang="scss" scoped>

  @import "../../assets/scss/components/vehicle-card.scss";

  .vehicle-information {
    @media screen and (max-width: 1024px) {
      flex-direction: column!important;
      height: fit-content;
      text-align: center;

      .title {
        text-align: center!important;
      }
    }
  }
  .usage-item {

    padding: 15px 10px;
    border-radius: 5px;
    margin: 0;
    margin-bottom: 0px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    .trip-info {
      margin-bottom: 10px;

      svg {
        margin-right: 15px;
      }

    }

  }
  .observations-card {
    p {
      padding: 20px;
      background: #e5e5e5;
      border-radius: 5px;
      color: black;
    }
  }

  .trip-info{
    text-transform: capitalize;
  }
  .trip-item-card {
    border: none!important;
    background-color: #ffffff;

    .checkout-table {
      padding: 10px 30px 30px!important;
      border-top: 1px solid #e5e5e5;

      .row {
        justify-content: space-between;
        margin-bottom: 10px;
        border-bottom: 1px solid #e5e5e5;
        color: black;
      }

    }

    hr {
      margin: 0!important;

      &:last-child {
        display: none!important;
      }
    }

    .card-info {
      padding: 30px 30px 0;

      @media (max-width: 980px) {
        padding: 20px 20px 0;
      }

      .type-tag {
          position: absolute;
          left: 0;
          z-index: 99;
          background: var(--default-color);
          color: white;
          padding: 5px 40px;
          top: 20px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
      }

    }

    .row-actions {
      .col-actions {
        .book-button {
            border-color: var(--default-color);
            color: white!important;
            background-color: var(--default-color);
            border-radius: 4px;
        }
      }
    }

    .first-row {
      padding: 30px 40px;
      @media (max-width: 1024px) {
        padding: 15px;
      }
    }

    .row-stop-points {
      .col-md-6 {

      }
    }
    .row-trip-info {
      background: #fff;
      margin: 0;
      padding: 30px 30px 0;
      -webkit-box-align: baseline;
      -ms-flex-align: baseline;
      align-items: baseline;

      &:last-child {
        border: none!important;
      }
      .trip-info {
          font-size: 16px;
          margin-bottom: 10px;
           color: black!important;

          * {
            color: black!important;
          }

          svg {
              margin-right: 15px!important;
          }
      }

      h4 {
        font-weight: 500!important;
      }
    }
    .col-info {
     .title {
        .type {
            color: #0558ff;
            font-size: 18px;
            width: 100%;
            display: block;
            opacity: .7;
        }
      }
      .configurations-wrapper {
        padding-top: 5px;

        span {
          background: transparent;
          color: #0d5bdc;
          margin-right: 5px;
          padding: 0 5px;
          margin-bottom: 5px;
          display: flex;
          border-color: #0d5bdc;
          border: 1px solid;
          border-radius: 5px;
        }
        p {
          text-align: left;
          margin-bottom: 0;
          font-size: 14px;
          color: #000;
          text-transform: capitalize;
          display: flex;
          flex-wrap: wrap;
        }
        .configurations {
          padding-top: 10px;
        }
      }
    }
    .configurations {
      span {

      }
    }
    .col-price  {
      .pricePerSeat {
        span {
          font-size: 14px;
          font-weight: 500;
          text-align: center;
        }
      }
      .more-info  {
        a {
          background-color: var(--default-color);
          border-color: var(--default-color);
          color: white;
        }
      }
    }

    .item-image-wrapper {
      display: flex;
      align-items: flex-start;
      flex-flow: column;
      justify-content: center;
    }

    .item-title {
      margin-bottom: 20px;
    }

    .price {
      font-size: 22px;
      color: #293c4b;
      font-weight: bold;
      text-align: right;
      @media (max-width: 980px) {
        text-align: center;
      }
    }

    .card-body {
      padding: 0;
      box-shadow: 0px 1px 6px rgba(61,67,76,0.15);
    }

    .item-image {
      max-width: 100%;
      border-radius: 8px;
      margin-bottom: 15px;
    }
  }
</style>
